/* Add these CSS rules to your VideoPlayer.css file */

/* Seeking overlay for host */
.seeking-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.seeking-message {
  background-color: rgba(44, 62, 80, 0.9);
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seeking-spinner,
.reconnection-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.seeking-message p {
  margin: 5px 0;
  font-size: 16px;
}

.seeking-message .small {
  font-size: 14px;
  opacity: 0.8;
  margin-top: 8px;
}

/* Reconnection overlay for viewers */
.reconnection-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.reconnection-message {
  background-color: rgba(231, 76, 60, 0.9);
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-time {
  font-size: 12px;
  opacity: 0.7;
  margin-top: 10px;
}

.refresh-button {
  background-color: white;
  color: #e74c3c;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.refresh-button:hover {
  background-color: #f5f5f5;
}

/* Enhanced connection status indicators */
.connection-status {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 8px 12px;
  transition: background-color 0.3s;
}

.connection-status.disconnected {
  background-color: rgba(231, 76, 60, 0.8);
}

.connection-status.reconnecting {
  background-color: rgba(243, 156, 18, 0.8);
  animation: pulse 1s infinite;
}

.status-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 14px;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot.disconnected {
  background-color: #e74c3c;
}

.status-dot.connecting {
  background-color: #f39c12;
  animation: pulse 1s infinite;
}

.status-dot.buffering {
  background-color: #3498db;
  animation: pulse 1s infinite;
}

.status-dot.error {
  background-color: #e74c3c;
  animation: pulse 1s infinite;
}

.status-dot.ready {
  background-color: #2ecc71;
}